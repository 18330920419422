<script setup lang="ts">
const { status } = useAuth()
const statusValue = status.value
</script>

<template>
  <div>
    <body class="flex flex-col min-h-screen min-h-svh">
      <header class="fixed w-full z-20">
        <UtilsHeader />
      </header>
      <main class="grow">
        <slot />
      </main>
      <footer>
        <UtilsFooter />
      </footer>
      <div class="fixed bottom-10 left-10 z-20">
        <UButton
            v-if="statusValue === 'authenticated'"
            icon="i-heroicons-adjustments-horizontal"
            size="xl"
            class="bg-ocean-950 rounded-full cursor-pointer"
            variant="solid"
            :trailing="false"
            :to="localePath('/admin/dashboard')"
        />
      </div>
    </body>
  </div>
</template>

<style>

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

* {
  font-family: 'Montserrat', sans-serif;
}
</style>
