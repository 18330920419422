<template>
  <div class="bg-white h-full border-t-8 border-ocean-950" aria-labelledby="footer-heading">
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8">
          <NuxtImg src="/assets/logo.webp" alt="logo" class="h-20 mx-auto sm:mx-0" />
          <div class="flex justify-center sm:justify-normal space-x-6">
            <NuxtLink v-for="item in navigation.social" :key="item.name" :to="item.href" target="_blank" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </NuxtLink>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-gray-900">{{ $t('footer.title-yachting') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.solutions" :key="item.name">
                  <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</NuxtLink>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">{{ $t('footer.title-activity') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.support" :key="item.name">
                  <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-gray-900">{{ $t('footer.title-company') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.company" :key="item.name">
                  <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</NuxtLink>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">{{ $t('footer.title-legal') }}</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.legal" :key="item.name">
                  <NuxtLink :to="localePath(item.href)" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 flex justify-between items-center">
        <p class="text-xs leading-5 text-gray-500">&copy; {{ currentYear }} InterYachts Monaco, Inc. {{ $t('footer.all-rights-reserved') }}.</p>
        <p class="text-xs leading-5 text-gray-500 font-semibold">{{ $t('footer.made-by') }} <a href="https://mve.mc/" class="text-red-500 hover:text-red-800" target="_blank">MVE</a></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, h } from 'vue'

const runtimeConfig = useRuntimeConfig()
const linkedin = runtimeConfig.public.linkedin
const instagram = runtimeConfig.public.instagram
const facebook = runtimeConfig.public.facebook
const currentYear = new Date().getFullYear();

const { t } = useI18n()

const navigation = {
  solutions: [
    { name: t('footer.section-yachts-for-sale'), href: '/yachts/sale' },
    { name: t('footer.section-charter'), href: '/yachts/rent' },
    { name: t('footer.section-berths'), href: '/berths' },
    { name: t('footer.section-build'), href: '#' },
  ],
  support: [
    { name: t('footer.section-news'), href: '#' },
    { name: t('footer.section-yacht-management'), href: '/yacht-management' },
    { name: t('footer.section-crew-management'), href: '#' },
  ],
  company: [
    { name: t('footer.section-about-us'), href: '/about-us' },
    { name: t('footer.section-contact'), href: '/contact' },
  ],
  legal: [
    { name: t('footer.section-privacy'), href: '#' },
    { name: t('footer.section-terms'), href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: facebook,
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'Instagram',
      href: instagram,
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'LinkedIn',
      href: linkedin,
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                d: 'M17.6429 1.4375H1.3529C0.606473 1.4375 0 2.08887 0 2.88848V20.1115C0 20.9111 0.606473 21.5625 1.3529 21.5625H17.6429C18.3893 21.5625 19 20.9111 19 20.1115V2.88848C19 2.08887 18.3893 1.4375 17.6429 1.4375ZM5.74241 18.6875H2.92634V9.0832H5.74665V18.6875H5.74241ZM4.33437 7.77148C3.43103 7.77148 2.70156 6.99434 2.70156 6.04199C2.70156 5.08965 3.43103 4.3125 4.33437 4.3125C5.23348 4.3125 5.96719 5.08965 5.96719 6.04199C5.96719 6.99883 5.23772 7.77148 4.33437 7.77148V7.77148ZM16.2984 18.6875H13.4824V14.0156C13.4824 12.9016 13.4612 11.4686 12.0192 11.4686C10.5518 11.4686 10.327 12.6814 10.327 13.9348V18.6875H7.51094V9.0832H10.2125V10.3949H10.2507C10.6281 9.64023 11.5484 8.84512 12.9183 8.84512C15.7683 8.84512 16.2984 10.8352 16.2984 13.4227V18.6875V18.6875Z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
  ],
}
</script>
